window.NotificationBanner = (randomId) => {
	return {
		swiper: null,
		init() {
			setTimeout(() => {
				this.swiper = new Swiper('.swiper-container'+randomId, {
					loop: true,
					autoplay: {
						delay: 5000,
					},
					slidesPerView: 1,
				});
			}, 0);
		}
	};
};
